import { useElementSize }  from '@kaliber/use-element-size'
import { useMediaQuery } from '@kaliber/use-media-query'

import { Line } from '/features/pageOnly/Line'
import { BlockWithTitle } from '/features/buildingBlocks/BlockWithTitle'

import mediaStyles from '/cssGlobal/media.css'
import styles from './BlockWithTitleAndLine.css'

export function BlockWithTitleAndLine({ text, title = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd) ?? false
  const { size: { height }, ref } = useElementSize()
  const lineHeight = isViewportMd && !(height > 500)
    ? height * 2
    : height + 100

  return (
    <section data-x='block-with-line' className={styles.component}>
      <div className={styles.lineContainer} style={{ height: lineHeight }}>
        <Line layoutClassName={styles.lineLayout} />
      </div>
      <div className={styles.textContainer} {...{ ref }}>
        <BlockWithTitle {...{ title, text }} />
      </div>
    </section>
  )
}
